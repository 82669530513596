<template>

  <div>

    <b-field
      label="Téléphone *"
      custom-class="is-small has-text-weight-light has-text-grey"
      :type="!isValidPhoneNumber ? 'is-danger' : ''"
      :message="!isValidPhoneNumber ? 'Numéro de téléphone invalide.' : ''"
    >

      <b-select
        placeholder="Pays"
        v-model="selectedCountry"
        rounded
      >
        <option
          v-for="(country, key) in fav"
          :key="key"
          :value="key"
          :label="`${country.emoji} +${country.phone}`"
        >
          {{ country.emoji }} {{ country.name }}
        </option>
        <option
          v-for="(country, key) in formatedCountries"
          :key="key"
          :value="key"
          :label="`${country.emoji} +${country.phone}`"
        >
          {{ country.emoji }} {{ country.name }}
        </option>
      </b-select>

      <b-input
        rounded
        v-model="formatedPhoneNumber"
        native-type="tel"
        expanded
        :disabled="!selectedCountry"
        placeholder="Téléphone"
        :required="required"
      />

    </b-field>

  </div>

</template>

<script>
import { countries } from 'countries-list';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';

export default {
  props: {
    preferedCountries: {
      type: Array,
      default: () => ['FR', 'GB'],
    },
    initialValue: {
      type: Object,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneNumber: '',
      countryCode: 'FR',
      formatedCountries: {},
      fav: {},
      loading: false,
      success: null,
      warn: null,
      error: null,
    };
  },
  watch: {
    initialValue: {
      immediate: true,
      handler(initialValue) {
        if (initialValue) {
          this.phoneNumber = initialValue.nationalNumber;
          this.countryCode = initialValue.country;
        }
      },
    },
  },
  created() {
    const c = { ...countries };

    this.preferedCountries.forEach((ct) => {
      this.fav[ct] = c[ct];
    });
    this.preferedCountries.forEach((ct) => {
      delete c[ct];
    });

    this.formatedCountries = c;
  },
  computed: {
    selectedCountry: {
      get() {
        return this.countryCode;
      },
      set(countryCode) {
        this.countryCode = countryCode;
      },
    },
    formatedPhoneNumber: {
      get() {
        return this.phoneNumber;
      },
      set(phone) {
        const formatedPhone = new AsYouType(this.countryCode).input(phone);
        const parsePN = parsePhoneNumber(formatedPhone, this.countryCode);

        if (parsePN) {
          delete parsePN.metadata;
          this.$emit('update-phone-number', { ...parsePN });
        }
        this.phoneNumber = formatedPhone;
      },
    },
    isValidPhoneNumber() {
      const phoneNumber = parsePhoneNumber(this.phoneNumber, this.countryCode);
      const isValid = phoneNumber ? (phoneNumber.isValid() === true) : false;

      this.$emit('isvalid', isValid);
      return !this.phoneNumber ? true : isValid;
    },
  },
};
</script>